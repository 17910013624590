import * as React from "react";
import Layout from "../../components/Layout";

const ContactUsPage = () => {
  return (
    <Layout pageTitle="Contact Us">
      <h1 className="text-2xl font-bold">Contact Us</h1>
    </Layout>
  );
};

export default ContactUsPage;
